// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-car-shop-detail-page-tsx": () => import("./../../../src/components/page/car-shop-detail-page.tsx" /* webpackChunkName: "component---src-components-page-car-shop-detail-page-tsx" */),
  "component---src-components-page-car-shop-overview-page-tsx": () => import("./../../../src/components/page/car-shop-overview-page.tsx" /* webpackChunkName: "component---src-components-page-car-shop-overview-page-tsx" */),
  "component---src-components-page-default-page-tsx": () => import("./../../../src/components/page/default-page.tsx" /* webpackChunkName: "component---src-components-page-default-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

